<template>
  <div class="content-wrap">
    <!------------------------------ 左部分区域 start ------------------------------>
    <div class="content-left">
      <!-- 进口总额排名 -->
      <div class="content-item import-ranking">
        <div class="content-title">
          <span>进口总额排名</span>
        </div>
        <div class="content-charts">
          <div class="ls-title">
            <span>Top10</span>
            <span>地区</span>
            <span>进口总额/亿元</span>
            <span>占比</span>
          </div>
          <div class="chart-item">
            <swiper :options="{ ...swiperOption, loop: false }">
              <swiper-slide v-for="(ele, idx) in infoData.importTop" :key="idx">
                <div class="ls-item">
                  <span>{{ idx + 1 }}</span>
                  <span>{{ ele.region }}</span>
                  <span>{{ ele.value }}</span>
                  <span>{{ ele.proportion }}</span>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
      <!-- 进口品种占比 -->
      <div class="content-item proportion-imported">
        <div class="content-title">
          <span>进口品种占比</span>
        </div>
        <div
          class="content-charts"
          v-loading="!infoData.breedImportPercentageList.length"
          element-loading-background="transparent"
        >
          <div
            id="proportion-imported-chart"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
      <!-- 国内市场流通排名 -->
      <div class="content-item domestic-market">
        <div class="content-title">
          <span>国内市场流通排名</span>
        </div>
        <div class="content-charts">
          <div
            id="domestic-market-chart"
            style="width: 100%; height: 100%"
            v-loading="!infoData.marketRankList.length"
            element-loading-background="transparent"
          ></div>
        </div>
      </div>
    </div>
    <!------------------------------ 左部分区域 end ------------------------------>

    <!------------------------------ 中部分区域 start ------------------------------>
    <div class="content-middle">
      <!-- 年总产值 -->
      <div class="total-num">
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="1"
              :start-val="0.01"
              :end-val="+infoData.annualOutputValueTotal || 0"
              :duration="2500"
              :decimals="2"
            ></CountTo>
          </div>
          <div class="item-txt">年总产值/亿美元</div>
        </div>
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="1"
              :start-val="0"
              :end-val="+infoData.exportTotalMoney || 0"
              :decimals="2"
              :duration="2500"
            ></CountTo>
          </div>
          <div class="item-txt">进口总额/亿美元</div>
        </div>
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="2"
              :start-val="0"
              :end-val="+infoData.importTotalMoney || 0"
              :decimals="2"
              :duration="2500"
            ></CountTo>
          </div>
          <div class="item-txt">出口总额/亿美元</div>
        </div>
      </div>
    </div>
    <!------------------------------ 中部分区域 end ------------------------------>

    <!------------------------------ 右部分区域 start ------------------------------>
    <div class="content-right">
      <!-- 出口总额排名 -->
      <div class="content-item ranking-exports">
        <div class="content-title">
          <span>出口总额排名</span>
        </div>
        <div class="content-charts">
          <div class="ls-title">
            <span>Top10</span>
            <span>地区</span>
            <span>进口总额/亿元</span>
            <span>占比</span>
          </div>
          <div class="chart-item">
            <swiper :options="{ ...swiperOption, loop: false }">
              <swiper-slide v-for="(ele, idx) in infoData.exportTop" :key="idx">
                <div class="ls-item">
                  <span>{{ idx + 1 }}</span>
                  <span>{{ ele.region }}</span>
                  <span>{{ ele.value }}</span>
                  <span>{{ ele.proportion }}</span>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
      <!-- 出口品种占比 -->
      <div class="content-item proportion-varieties">
        <div class="content-title">
          <span>出口品种占比</span>
        </div>
        <div class="content-charts">
          <div
            id="proportion-varieties-chart"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
      <!-- 国内市场品种流通占比 -->
      <div class="content-item circulation-ratio">
        <div class="content-title">
          <span>国内市场品种流通占比</span>
        </div>
        <div class="content-charts">
          <div
            id="circulation-ratio-chart"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
    </div>
    <!------------------------------ 右部分区域 end ------------------------------>
  </div>
</template>
<script>
import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
let worldGeoCoordMap, cityMap, worldMap, worldDatas

import { methods } from '../modules/methods'

// import videoDemo from '../../utils/videoDemo/EZUIKitJs.vue'
import CountFlop from '../../../components/count-to-component.vue'
import containerTab from '../../../components/container-tab.vue'

import * as $echarts from 'echarts'
import CountTo from 'vue-count-to'
export default {
  name: 'logistics-trade',
  components: {
    // videoDemo,
    CountFlop,
    containerTab,
    CountTo,
    swiper,
    swiperSlide,
  },
  inject: ['app'],
  data() {
    return {
      infoData: {
        breedExportPercentageList: [], //出口品种占比
        breedImportPercentageList: [], // 	进口品种占比
        exportLogisticsList: [], // 	出口物流 - 流线图
        exportTop: [], // 出口总额排名
        importLogisticsList: [], //进口物流
        importTop: [], //进口物流
        marketBreedPercentageList: [], // 国内市场品种流通占比
        marketRankList: [], //国内市场品种流通占比
      },
      swiperOption: {
        // 所有的参数同 swiper 官方 api 参数
        // spaceBetween: 18,
        autoplay: {
          disableOnInteraction: false,
          delay: 1000, //1秒切换一次
        },
        // mousewheel: true,
        slidesPerView: 5,
        loop: true,
        direction: 'vertical',
      },
      params: {},
      map: null,
      // 进口品种占比
      proportion: {
        idName: 'proportion-imported-chart',
        series: {
          data: [],
        },
      },
      // 出口品种占比
      varieties: {
        idName: 'proportion-varieties-chart',
        series: {
          data: [],
        },
      },
      // 国内市场品种流通占比
      circulation: {
        idName: 'circulation-ratio-chart',
        series: {
          data: [
            // {
            //   value: 6000000,
            //   name: '罗非鱼',
            // },
          ],
        },
      },
      // 国内市场流通排名
      domestic: {
        idName: 'domestic-market-chart',
        yAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },
    }
  },
  computed: {},
  methods: {
    // 世界流线图
    globalLine() {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById('global_canvas'))

      $echarts.registerMap('world', worldMap)

      for (let key in worldGeoCoordMap) {
        let coordX = worldGeoCoordMap[key][0]
        if (coordX <= -30) {
          worldGeoCoordMap[key][0] = 360 + coordX
        }
      }

      let coordData = worldDatas.map((item) => {
        if (!worldGeoCoordMap[item.name]) {
          console.log('该地区不存在: ' + item.name)
          return
        }
        let value = [...worldGeoCoordMap[item.name], item.value]
        return {
          name: item.name,
          value: value,
        }
      })

      let convertData = (data) => {
        let res = []
        for (let i = 0; i < data.length; i++) {
          let dataItem = data[i]
          let toCoord = worldGeoCoordMap[dataItem.name]
          let fromCoord = worldGeoCoordMap['广州']
          // toCoord = worldGeoCoordMap[dataItem.senderAddress]
          // fromCoord = worldGeoCoordMap[dataItem.recipientAddress]
          if (fromCoord && toCoord) {
            res.push([
              {
                coord: fromCoord,
                value: 1.5,
              },
              {
                coord: toCoord,
              },
            ])
          }
        }
        return res
      }
      var series = []
      let obj = [['', worldDatas]]

      obj.forEach(function (item, i) {
        series.push(
          {
            type: 'lines',
            zlevel: 2,
            effect: {
              show: true,
              period: 4, //箭头指向速度，值越小速度越快
              trailLength: 0.2, //特效尾迹长度[0,1]值越大，尾迹越长重
              symbol: 'circle',
              symbolSize: 5, //图标大小
            },
            lineStyle: {
              normal: {
                type: [4, 4],
                color: 'red',
                width: 1, //尾迹线条宽度
                opacity: 1, //尾迹线条透明度
                curveness: 0.3, //尾迹线条曲直度
              },
            },
            data: convertData(item[1]),
          },
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            zlevel: 2,
            rippleEffect: {
              //涟漪特效
              period: 4, //动画时间，值越小速度越快
              brushType: 'stroke', //波纹绘制方式 stroke, fill
              scale: 3, //波纹圆环最大限制，值越大波纹越大
            },
            label: {
              normal: {
                show: true,
                areaColor: 'red',
                position: 'right', //显示位置
                offset: [5, 0], //偏移设置
                formatter: function (params) {
                  //圆环显示文字
                  return params.data.name.replace(/中国\//g, '')
                },
                fontSize: fontHandle(14),
              },
              emphasis: {
                show: true,
              },
            },
            symbol: 'circle',
            symbolSize: function (val) {
              return fontHandle(4 + val[2] * 5) //圆环大小
            },
            itemStyle: {
              normal: {
                show: true,
                color: '#eb9d8e', // 字体颜色
              },
            },
            data: coordData,
          }
        )
      })

      let option = {
        backgroundColor: '#072341',
        title: {
          text: '',
          subtext: '',
          left: 'center',
          top: fontHandle(-3),
          itemGap: 0,
          textStyle: {
            color: '#fff',
            fontSize: fontHandle(20),
          },
          z: 200,
        },
        tooltip: {
          show: false,
          trigger: 'item',
          backgroundColor: '#f00',
          borderColor: '#ff0',
          showDelay: 0,
          hideDelay: 0,
          enterable: true,
          transitionDuration: 0,
          extraCssText: 'z-index:100',
          formatter: function (params, ticket, callback) {
            //根据业务自己拓展要显示的内容
            var res = ''
            var name = params.name
            var value = params.value[params.seriesIndex + 1]
            res =
              "<span style='color:#fff;'>" + name + '</span><br/>数据：' + value
            return res
          },
        },
        visualMap: [
          {
            show: false,
            seriesIndex: 0,
            dimension: 0, //取第一维度,批次
            right: fontHandle(10),
            bottom: fontHandle(10),
            zlevel: fontHandle(10),
            calculable: true,
            min: 0,
            max: fontHandle(10),
            inRange: {
              //由低到高
              color: ['#DBDDE6', '#DBDDE6'],
            },
            textStyle: {
              color: '#fff',
              fontSize: fontHandle(14),
            },
          },
        ],
        geo: {
          map: 'world',
          roam: false, //不开启缩放和平移
          zoom: 1, //视角缩放比例
          nameMap: cityMap,
          layoutCenter: ['50%', '60%'], //地图位置
          layoutSize: '112%',
          label: {
            normal: {
              show: false,
              fontSize: fontHandle(10),
              color: 'rgba(0,0,0,0.7)',
            },
            emphasis: {
              show: true,
              color: '#fff',
            },
          },
          itemStyle: {
            normal: {
              // borderColor: 'rgba(0, 0, 0, 0.7)'
              areaColor: '#001636',
              borderColor: '#A72E3F',
              shadowColor: '#092f8f',
              shadowBlur: 0,
              opacity: 1,
            },
            emphasis: {
              areaColor: '#F3B329', //鼠标选择区域颜色
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: fontHandle(20),
              borderWidth: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
              opacity: 1,
            },
          },
        },
        series: series,
      }
      chart.setOption(option)
    },
    // 折线图2
    domesticMarketChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
        },
        color: ['#b2f4f8', '#ffcb4d', '#12fffe'],
        legend: {
          show: false,
          data: ['大学教育程度', '35岁以上观众', '所有观众'],
          bottom: fontHandle(10),
          textStyle: {
            color: '#fff',
            fontSize: fontHandle(16),
          },
          icon: 'circle',
          itemWidth: fontHandle(16),
          itemHeight: fontHandle(16),
          itemGap: fontHandle(35),
        },
        grid: {
          left: fontHandle(11, 'px'),
          right: fontHandle(18, 'px'),
          bottom: fontHandle(16, 'px'),
          top: fontHandle(18, 'px'),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'value',
            nameTextStyle: {
              color: '#ffffff',
            },
            offset: 0,
            axisLabel: {
              color: '#838DA1',
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#12fffe',
                width: 0,
                type: 'solid',
              },
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'category',
            data: obj.yAxis.data,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#46494A',
                width: 1,
                type: 'solid',
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,

              textStyle: {
                color: '#838DA1',
              },
            },
          },
        ],
        series: [
          {
            name: '所有观众',
            type: 'bar',
            data: obj.series.data,
            barWidth: fontHandle(10),
            barGap: 1,
            itemStyle: {
              normal: {
                barBorderRadius: fontHandle(10),
                color: new $echarts.graphic.LinearGradient(
                  1,
                  0,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: '#6BE0BD',
                    },
                    {
                      offset: 0.5,
                      color: '#089DDD',
                    },
                    {
                      offset: 1,
                      color: '#FE69FB',
                    },
                  ],
                  false
                ),
              },
            },
          },
        ],
      }
      chart.setOption(option)
    },
    // 饼图
    proportionImportedChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let option = {
        backgroundColor: 'transparent',
        color: [
          '#5DE9C4',
          '#E654EB',
          '#5442FF',
          '#EBA447',
          '#4898FB',
          '#0CCCCE',
        ],
        tooltip: {
          trigger: 'item',
        },
        legend: {
          itemWidth: fontHandle(10),
          itemHeight: fontHandle(10),
          type: 'scroll',
          orient: 'vertical',
          right: '8%',
          top: fontHandle(26),
          itemGap: fontHandle(24),
          textStyle: {
            color: '#FFFFFF',
            fontSize: fontHandle(16),
            rich: {
              a: {
                color: '#999',
                width: fontHandle(100),
              },
              b: {
                color: '#23c3ff',
                width: fontHandle(80),
                align: 'right',
              },
              c: {
                color: '#ffffff',
                width: fontHandle(100),
                align: 'left',
              },
            },
          },
        },
        series: [
          {
            itemStyle: {
              borderWidth: 2, //设置border的宽度有多大
              borderColor: 'rgba(0,0,0,0.2)',
              normal: {
                labelLine: {
                  show: true,
                  color: '#ff0',
                },
              },
            },
            smooth: true,
            center: ['36%', '50%'],
            name: '',
            type: 'pie',
            radius: ['20%', '70%'],
            avoidLabelOverlap: true, //默认开启，在标签拥挤重叠的情况下会挪动各个标签的位置，防止标签间的重叠。
            data: obj.series.data,
            label: {
              show: true,
              formatter: '\n{b}\n\n{d}%',
              position: 'outside',
              color: '#9BA7BF',
              fontSize: fontHandle(12),
            },
            labelLine: {
              normal: {
                show: true,
                lineStyle: {
                  type: 'solid',
                  color: '#9BA7BF',
                },
              },
            },
          },
        ],
      }
      chart.setOption(option)
    },
    ...methods,
    initGlobalLineChart() {
      if (worldDatas && this.infoData.exportLogisticsList.length) {
        this.infoData.exportLogisticsList =
          this.infoData.exportLogisticsList.map((ele) => {
            let temp = ele.recipientAddress.split(',')
            return {
              name: temp[0].includes('中国') ? temp[1] : temp[0],
              value: 1,
            }
          })

        worldDatas = [worldDatas[0], ...this.infoData.exportLogisticsList]
        this.globalLine()
      }
    },
  },
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后

    import(/* webpackChunkName: "mapdata" */ '../../../utils/world-datas').then(
      (alldata) => {
        worldGeoCoordMap = alldata.worldGeoCoordMap
        cityMap = alldata.cityMap
        worldMap = alldata.worldMap
        worldDatas = alldata.worldDatas
        this.initGlobalLineChart()
      }
    )

    this.$axios('/zhanjiang-dataview/viewLogisticsTrade/queryInfo')
      .then((res) => {
        this.infoData = res.data
        this.domestic.yAxis.data = res.data.marketRankList.map(
          (ele) => ele.name
        )
        this.domestic.series.data = res.data.marketRankList.map(
          (ele) => +ele.value
        )

        this.proportion.series.data = res.data.breedImportPercentageList.map(
          (ele) => {
            ele.value = +ele.value
            return ele
          }
        )

        this.varieties.series.data = res.data.breedExportPercentageList.map(
          (ele) => {
            ele.value = +ele.value
            return ele
          }
        )

        this.circulation.series.data = res.data.marketBreedPercentageList.map(
          (ele) => {
            ele.value = +ele.value
            return ele
          }
        )

        console.log('alldata', this.domestic, this.proportion, this.varieties)

        this.initGlobalLineChart()
        this.domesticMarketChart(this.domestic)
        this.proportionImportedChart(this.proportion)
        this.proportionImportedChart(this.varieties)
        this.proportionImportedChart(this.circulation)
      })
      .catch(() => {})
  },
  created() {},
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
@import './logistics-trade.less';

</style>
